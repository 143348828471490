<template>
  <el-dialog title="介绍信息" class="info-div" style="padding-top: 10px">
    <el-form :model="form" ref="form">
      <el-form-item label="备注" :label-width="formLabelWidth">
        <el-input v-model="Info.content" type="textarea" rows="8" maxlength="3000" />
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
export default {
  name: "index",
  props:['Row'],
  data(){
    return{
      Info:[]
    }
  },
  methods:{
    closeDiv(){
      this.$emit('closeDiv');
    },
    getInfo(row){//清空表单
      //this.$refs.$options.data().form;
      this.Info=row;
      console.log(this.Info);
    },
  }
}
</script>

<style scoped>

</style>